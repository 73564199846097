import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TeamCard from "../components/TeamCard";

function TeamPage() {
  return (
    <Layout>
      {() => (
        <>
          <SEO keywords={[]} title="Team" />
          <div className="relative max-w-lg mx-auto lg:max-w-6xl">
            <div className="mt-12">
              <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                Team
              </h2>
              <p className="mt-3 text-xl leading-7 text-gray-600 sm:mt-4">
                I collaborate with a network of skilled fellow engineers and
                designers to cover a wide range of workloads
              </p>
            </div>
            <div className="mt-6 grid gap-16 border-t-2 border-gray-100 pt-12 lg:grid-cols-3 lg:col-gap-5 lg:row-gap-12">
              <TeamCard
                name="Julian Rubisch"
                job="Proprietor, Software Developer"
                email="info@minthesize.com"
                location="Vienna, Austria"
                technologies={["Ruby", "Rails", "Elixir", "Phoenix", "React"]}
              />
              <TeamCard
                name="Daniel Kauer"
                job="Software Developer, Interaction Designer"
                email="mail@danielkauer.at"
                location="Vienna, Austria"
                technologies={["React", "Electron", "iOS", "Android"]}
              />
              <TeamCard
                name="Tobias Leibetseder"
                job="Graphic & UI Designer"
                email="t.leibetseder@restruct.com"
                location="Vienna, Austria"
                technologies={["Sketch", "Invision", "Affinity"]}
              />
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

export default TeamPage;
