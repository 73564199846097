import PropTypes from "prop-types";
import React from "react";
import SparkMD5 from "spark-md5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/pro-regular-svg-icons";

const TeamCard = ({ name, job, email, location, technologies }) => {
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0">
        <img
          className="h-100 w-full object-cover"
          src={`https://www.gravatar.com/avatar/${SparkMD5.hash(
            email
          )}?s=1024&d=mp`}
          alt=""
        />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm leading-5 font-medium text-magenta-900">
            {job}
          </p>
          <h3 className="mt-2 text-xl leading-7 font-medium text-gray-900">
            {name}
          </h3>
          <p className="text-sm leading-5 font-base text-gray-500">
            <FontAwesomeIcon icon={faMapMarker} className="mr-2" />
            {location}
          </p>
        </div>
        <div className="mt-4 flex justify-around w-1/2 m-auto flex-wrap">
          {technologies.map((tech) => (
            <span
              key={tech}
              className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-100 text-magenta-900 mt-2"
            >
              {tech}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

TeamCard.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  job: PropTypes.string.isRequired,
  location: PropTypes.string,
  technologies: PropTypes.array.isRequired,
};

export default TeamCard;
